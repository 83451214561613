import React from "react";
import styled from "styled-components";
import { BackButton } from "../../components/button";
import { PimcoreImage } from "../../components/pimcore-image";
import { ContentWrapper, RichTextField } from "../../components/text-content-wrapper";
import { Routes } from "../../data/routing";
import { BlogPostInterface } from "../../graphql/blog";

interface BlogPostProps {
  post: BlogPostInterface;
}

const BlogPost: React.FC<BlogPostProps> = ({ post }) => {
  return (
    <Wrapper>
      <BackButtonWrapper>
        <BackButton to={Routes.blog} shareTo={typeof window !== "undefined" ? window.location.href : ""}>
          Back to Blog
        </BackButton>
      </BackButtonWrapper>
      <ContentWrapper withXBackground>
        <PostContent>
          <Date>{post.date}</Date>
          <Title>{post.title}</Title>
          <Introduction>
            <RichTextField dangerouslySetInnerHTML={{ __html: post.introduction }} />
          </Introduction>
          <PhotoWrapper>
            {post.photo && <PimcoreImage image={post.photo} loading="eager" withAspectRatio />}
          </PhotoWrapper>
          <Content>
            <RichTextField dangerouslySetInnerHTML={{ __html: post.content }} />
          </Content>
        </PostContent>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({
  className: "pb-8 tablet:px-40 tablet:pb-16",
})``;

const BackButtonWrapper = styled.div.attrs({ className: "tablet:mb-9" })``;

const PostContent = styled.div.attrs({ className: "relative z-10" })``;

const Date = styled.div.attrs({
  className: "text-gray4 text-sm text-center",
})``;

const Title = styled.div.attrs({
  className:
    "text-gray px-10 mb-6 text-subHeadlineMobile font-light text-center tablet:text-modalHeadline tablet:mb-12 richtablet:px-36",
})``;

const Introduction = styled.div.attrs({
  className: "text-gray px-5 mb-8 tablet:mb-16 tablet:px-28",
})``;

const PhotoWrapper = styled.div.attrs({ className: "mb-8 tablet:mb-16" })``;

const Content = styled.div.attrs({ className: "text-gray px-5 tablet:px-28" })``;

export default BlogPost;
