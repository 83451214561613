import * as React from "react";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import styled from "styled-components";
import BlogPost from "../sections/blog/blog-post";
import RecentBlogPostList from "../components/blog-post/blog-post-list";
import { graphql, PageProps } from "gatsby";
import { BlogPostInterface, BlogPostListingInterface } from "../graphql/blog";
import { JobOffersCountInterface } from "../graphql/jobs";
import Layout from "../components/layout";

interface BlogPostDataInterface {
  pimcore: {
    post: BlogPostInterface;
    recentPosts: BlogPostListingInterface;
    jobOffersCount: JobOffersCountInterface;
  }
}

const RECENT_POSTS_LIMIT: number = 6;

const filterRecentPosts = (recentPosts: Array<BlogPostInterface>, post: BlogPostInterface): Array<BlogPostInterface> =>
  recentPosts.filter(recentPost => recentPost.slug !== post.slug).slice(0, RECENT_POSTS_LIMIT);

const BlogPostPage: React.FC<PageProps<BlogPostDataInterface>> = ({ data }) => {
  const post = data.pimcore.post;

  return (
    <Layout
      navCurrentItem="blog"
      title={post.metaTitle}
      description={post.metaDescription}
      canonical={process.env.GATSBY_BASE_URL + '/blog/' + post.slug + '/'}
      openGraph={{
        title: post.metaTitle,
        description: post.metaDescription,
        image: post.photo?.srcset?.[1]?.resolutions?.[1]?.url ? (process.env.GATSBY_BASE_URL + post.photo.srcset[1].resolutions[1].url) : undefined,
        type: 'article',
      }}
      schemaOrg={post.metaSchema}
    >
      <Main>
        <BlogPost post={data.pimcore.post} />
        <RecentBlogPostList
          headline="Blog posts"
          posts={filterRecentPosts(data.pimcore.recentPosts.edges.map(edge => edge.node), data.pimcore.post)}
        />
        <TileNavigation top={"team"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
    </Layout>
  );
};

const Main = styled.div.attrs({ className: "pt-20 tablet:pt-32" })``;

export const query = graphql`
  query($id: Int!) {
    pimcore {
      post: getBlogPost(id: $id) {
        title
        introduction
        content
        date
        photo { 
          ...thumbnailFull
        }
        slug
        metaTitle
        metaDescription
        metaSchema
      }
      ...recentPosts
      ...jobOffersCount
    }
  }
`;

export default BlogPostPage;
