import React from "react";
import styled from "styled-components";
import { BlogPostInterface } from "../../graphql/blog";
import { theme } from "../../styles/styles";
import BlogPostListItem from "./blog-post-list-item";
import HorizontalSlider from "../horizontal-slider/horizontal-slider";

interface RecentBlogPostListProps {
  headline?: string;
  posts: Array<BlogPostInterface>;
  listPadding?: string;
}

const RecentBlogPostList: React.FC<RecentBlogPostListProps> = ({ headline, posts, listPadding }) => {
  return (
    <Wrapper>
      {headline && <Headline>{headline}</Headline>}
      <PaddingWrapper>
        <HorizontalSlider
          padding={listPadding || "px-5 tablet:px-40"}
          progressMargin="mt-6 tablet:mb-0"
        >
          {posts.map((post, index) => (
            <ListItem key={`recent_post_${index}`}>
              <BlogPostListItem post={post} variant={"sm"} />
            </ListItem>
          ))}
        </HorizontalSlider>
      </PaddingWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({ className: "pb-5 tablet:pb-36" })``;
const PaddingWrapper = styled.div.attrs({ className: "pt-8" })``;

const Headline = styled.h3.attrs({
  className: "font-light text-white text-paragraphTablet px-5 tablet:px-40",
})``;

const ListItem = styled.div.attrs({ className: "" })`
  width: 19rem;

  @media ${theme.mediaQueries.tablet} {
    width: 22rem;
  }
`;

export default RecentBlogPostList;
